/**** Default CSS ****/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

htnml {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #C99A2E !important;
  color: #000 !important;
  font-family: 'Inter', !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
  color: #000 !important;
  font-weight: 400;
}

a:hover {
  text-decoration: none;
}
img {
  max-width: 100%;
  display: block;
  height: auto;
}

.items {
  display: flex;
  justify-content: space-between;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/**** Navbar ****/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #C99A2E;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 720;
  color: #000000 !important;
  letter-spacing: 0.9px;
  padding: 0 25px;
  font-size: 14px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}
.social-icon {
    display: inline-block;
    margin-left: 14px;
}
.social-icon a {
    width: 42px;
    height: 42px;
    background: 000000(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid 000000(255, 255, 255, 0.5);
}
.social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
    transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #000000;
  border: 1px solid #000000;
  padding: 18px 34px;
  font-size: 14px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #000000;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #000000;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #000000;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #52565e;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #52565e;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

@media (max-width: 768px) {
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-size: 12px;
  }
}

/* Adjust container padding for smaller screens */
@media (max-width: 480px) {
  main .container {
    padding: 0 10px;
  }
}

/* Adjust image sizes for smaller screens */
@media (max-width: 768px) {
  .social-icon a img {
    width: 30%;
  }
}

/**** Banner ****/
.banner {
  margin-top: 0;
  padding: 240px 0 100px 0;
  background-image: linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, .4)), url('../assets/img/portfoliobackgroundeg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #B39903 -5.91%, #CACDAC 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 38px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  font-family: 'Inter';
  text-decoration:double;
  color: #000000;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 18px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/**** Intro ****/
.intro {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-color: #F0EAD6;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.intro .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, #B39903 -5.91%, #CACDAC 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 38px;
  margin-bottom: 16px;
  display: inline-block;
}
.intro h1 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.intro h3 {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.intro p {
  font-family: 'Inter';
  color: #000;
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.intro button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.intro button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.intro button:hover svg {
  margin-left: 25px;
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

.intro .hobbies {
  border-style: dotted;
  font-size: 18px;
  padding: 12px;
  margin-right: 28px;}

/**** Skills ****/
.skill {
  background-image: linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, .4)), url('../assets/img/gradient1.png');
  padding: 0 0 50px 0;
  position: relative;
}
.skill-box {
  background: #F0EAD6;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
  border: solid;
}
.skill h2 {
	font-size: 42px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.skill .img-gradient {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/**** Projects ****/
.project {
  padding: 80px 0;
  position: relative;
  background-color: #F0EAD6;
}
.project h2 {
	font-size: 42px;
	font-weight: 700;
  text-align: center;
}
.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 78%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 100%;
}
@media (min-width: 768px) {
  .project .nav.nav-pills .nav-item {
    width: 25%;
  }
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 15px 10px;
  font-size: 18px;
  color: #fff;
  width: 100%;
  font-size: 22px;
  letter-spacing: 1.2px;
  font-weight: 500;
  position: relative;
  transition: 0.2s ease-in-out;
  text-align: center;
  z-index: 0;
}
@media (min-width: 768px) {
  .project .nav.nav-pills .nav-link {
    padding: 20px 0;
    font-size: 22px;
  }
}

.project .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #ff6c5f -5.91%, #7F7543 111.58%);
    top: 0;
    left: 0;
    z-index: -1;
    transition: 0.2s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link {
  border-radius: 55px;
}

@media (min-width: 768px) {
  .nav-link#projects-tabs-tab-first {
    border-radius: 55px 0px 0px 55px;
  }
  .nav-link#projects-tabs-tab-second,
  .nav-link#projects-tabs-tab-third {
    border-radius: 0;
  }
  .nav-link#projects-tabs-tab-fourth {
    border-radius: 0 55px 55px 0;
  }
}

.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-fourth {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-img-display{
  justify-content: center;
  height: 100%;
  max-height: 250px;
  width: 100%;
  overflow: hidden;
  border-radius: 30px;
}
.proj-imgbx {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
  width: auto;
}
.proj-imgbx img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #ff6c5f -5.91%, #7F7543 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  color: black;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h5 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/************ Contact ************/
.contact {
  background: linear-gradient(90.21deg, #ff6c5f -5.91%, #7F7543 111.58%);
  padding: 60px 0 200px 0;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 42px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #F0EAD6;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #F99790;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

/**** Footer ****/
.footer {
  padding: 0 0 50px 0;
  background-image: linear-gradient(rgba(255, 255, 255, .4), rgba(255, 255, 255, .4)), url('../assets/img/portfoliobackgroundeg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-color: transparent;
}
.footer p {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0.5px;
  margin-top: 20px;
  padding: 0px 0;
  background-color: transparent;
}
.social-icon-footer {
  display: inline-block;
  margin-left: 14px;
}
.social-icon-footer a {
  width: 88px;
  height: 88px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon-footer a::before {
  content: "";
  width: 88px;
  height: 88px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.social-icon-footer a:hover::before {
  transform: scale(1);
}
.social-icon-footer a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.social-icon-footer a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}